import contractInfo from "../contractInfo";
const { getWeb3, getMetamaskAddress } = require("../web3");
// const contractInfo = require("../contractInfo");
const getContractMethods = async ({ marketAddress }) => {
  const web3 = await getWeb3();
  const methods = new web3.eth.Contract(contractInfo.market.abi, marketAddress)
    .methods;
  // console.log(methods, 'methods factory==>>');
  return methods;
};

const resolveMarket = async ({outcomeIndex, marketAddress},onTxHash) => {
  try {
    const userAddress = await getMetamaskAddress(); // Get the user's Metamask address

    // Retrieve contract methods
    const methods = await getContractMethods({ marketAddress });
    let receipt;
    // Send transaction to resolve market and return the receipt
    if(outcomeIndex === -1){
      receipt = await methods
        .draw()
        .send({ from: userAddress })
        .on("transactionHash", (hash) => {
          // Return the transaction hash
          
          return onTxHash(hash)
        });
    }else{
      receipt = await methods
      .resolveMarket(outcomeIndex)
      .send({ from: userAddress })
      .on("transactionHash", (hash) => {
        // Return the transaction hash
        
        return onTxHash(hash)
      });
    }
   

    return { success: true, receipt };
  } catch (error) {
    throw new Error(error?.message)
  }
  }
const getProfit = async (marketAddress) => {
  try {
    const address = await getMetamaskAddress();
    console.log("calling function", {
      marketAddress,
      from: address,
    });
    const methods = await getContractMethods({
      marketAddress,
    });

    const total = await methods.BalanceOff().call();
    if(total == 0){
      return {success: true, balance: false }
    }
    
    const receipt = await methods
      .getAdminAmount()
      .send({ from: address })
      // .on("transactionHash", (hash) => {
      //   addTransaction(hash);
      // });
    return { success: true, receipt, balance: true };
  } catch (err) {
    return { success: false, err };
  }
};

const checkMarketBalance = async (marketAddress) => {
  const methods = await getContractMethods({
    marketAddress,
  });

  const total = await methods.BalanceOff().call();
  return total;
}

const getTotalMarketCount = async (marketAddress) => {
  const methods = await getContractMethods({
    marketAddress,
  });

  let totalBets = 1000
}

const distributeLiquidity = async (marketAddress, addTransaction) => {
  const address = await getMetamaskAddress();
  const methods = await getContractMethods({
    marketAddress,
  });
  methods
    .DistributeLiquidity()
    .send({ from: address })
    .on("transactionHash", (hash) => {
      addTransaction(hash);
    });
};
export { resolveMarket, distributeLiquidity, getProfit, checkMarketBalance, getTotalMarketCount };
